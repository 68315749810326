@import 'fonts';
@import 'palette';
@import 'spacing';

.main {
    align-items: center;
    background-color: $neutral-color;
    display: flex;
    flex-direction: column;
}

.container {
    margin: 0 auto;
    padding: $spacing-7 $spacing-5;

    @media (min-width: 640px) {
        padding: $spacing-11 0;
        width: $w-content-max-1;
    }
}

.title {
    color: $brand-color;
    font-family: $ff-interstate-black;
    font-size: $fs-7;
    line-height: 1;
    margin-bottom: $spacing-6;
    text-align: center;

    @media (min-width: 640px) {
        font-size: $fs-012;
        margin-bottom: $spacing-10;
    }
}

.subtitle {
    font-size: $fs-1;
    line-height: $lh-3;

    @media (min-width: 640px) {
        font-size: $fs-4;
    }
}

.col1 {
    text-align: center;
}

.col2 {
    display: flex;
    flex-direction: column;

    @media (min-width: 640px) {
        flex-direction: row;
        justify-content: space-between;
    }

    > div {
        @media (min-width: 640px) {
            flex-basis: 42%;
        }
    }
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.linkButton {
    align-self: center;
    display: inline-block;
    margin-top: $spacing-4;
    min-width: $spacing-20;
    text-align: center;
    width: auto;

    @media (min-width: 640px) {
        align-self: flex-start;
        margin-top: $spacing-6;
    }
}
